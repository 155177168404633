import React from "react"
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import IpoPopup from "../../components/IpoPopup"
import VideoNews from '../../components/VideoNews'
import Details from '../../components/Details'
import Hero from '../../components/Hero'
import ShorterContent from '../../components/ShorterContent'
import Footer from '../../components/Footer'
import Menu from '../../components/Menu'

export const query = graphql`
  query {
    datoCmsIpo {
      heroText
      heroBackground {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      intro
      offer
      links {
        ... on DatoCmsLinkWithIcon {
          id
          linkTarget
          linkTitle
          icon {
            url
          }
          highlighted
        }
        ... on DatoCmsDocumentLink {
          id
          linkTitle
          document {
            url
          }
          highlighted
        }
      }
      ipo
      video {
        id
        spotifyLink
        videoLink
        oembedCode
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      faq {
        id
        title
        content
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsNewHome( locale: { eq: "en" }) {
      videosTitle
      videoNews {
        id
        spotifyLink
        videoLink
        oembedCode
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    datoCmsNewMenu {
      ...Menu
    }
    datoCmsFooter {
      ...Footer
    }
  }
  `


export default function IPO({ data, pageContext }) {
  const { heroText, heroBackground, ipo, links, intro, offer, video, faq, seoMetaTags } = data.datoCmsIpo
  const { videosTitle, videoNews } = data.datoCmsNewHome
  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <HelmetDatoCms seo={seoMetaTags}>
        <title>Nordic Asia - Investment Advisory Group | IPO</title>
      </HelmetDatoCms>
      <Layout transparentMenu locale={pageContext.locale}>
          <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <div className="prose">
          <Hero heroBackground={heroBackground} >
            <div
              dangerouslySetInnerHTML={{
                __html: heroText,
              }} />
          </Hero>
          <section className="container center-width" >
            <ShorterContent>
              <div dangerouslySetInnerHTML={{
                __html: intro,
              }}></div>
            </ShorterContent>
          </section>

          <div className="gray-background">
            <div className="container center-width">
              <VideoNews news={video} className="ipo-video" />
            </div>
          </div>

          <section className="container center-width" id="erbjudandet">
            <ShorterContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: offer,
                }}></div>
              {links.map(link =>
                <div key={link.linkTitle}>
                  <a className={`ipo-link text-left full-width margin-bottom-small small btn ${!link.highlighted && 'btn--outline'}`} href={link.document ? link.document.url : link.linkTarget}>
                    <div className="flex flex--v-center">
                      {link.icon ?
                        <img src={link.icon.url} alt="" style={{ height: link.highlighted ? '2em' : '1em' }} className="margin-right-small" /> :
                        <FontAwesomeIcon icon={faFilePdf} style={{ height: link.highlighted ? '2em' : '1em' }} className="margin-right-small" />
                      }
                      {link.linkTitle}
                    </div>
                  </a>
                </div>
              )}
            </ShorterContent>
          </section>

          <section className="container center-width" >
            <ShorterContent>
              <div dangerouslySetInnerHTML={{
                __html: ipo,
              }}></div>
            </ShorterContent>
          </section>


          <section className="gray-background">
            <div className="container center-width">
              <div className="grid">
                <div className="col col--lg-6">
                  {faq.slice(0, Math.ceil(faq.length / 2)).map(faq => (
                    <Details title={faq.title} content={faq.content} key={faq.id} />
                  ))}
                </div>
                <div className="col col--lg-6">
                  {faq.slice(Math.ceil(faq.length / 2)).map(faq => (
                    <Details title={faq.title} content={faq.content} key={faq.id} />
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="container center-width ipo-video">
            <h2 className="margin-top-small">{videosTitle}</h2>
            <VideoNews news={videoNews} />
          </section>

          <IpoPopup />
        </div>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
