import React, { useState } from "react"
import { CookiesProvider, useCookies } from 'react-cookie'
import { StaticQuery, graphql } from "gatsby"

export default function IpoPopup() {
  const [cookies, setCookie] = useCookies(['name'])
  const [popupVisible, setPopupVisible] = useState(true)
  function acceptPopup() {
    setPopupVisible(false)
    setCookie('ipoRegulatory', 'ye')
  }

  return (
    <StaticQuery
      query={graphql`
      query IpoPopupQuery {
        datoCmsIpo {
          popup
        }
      }
    `}
      render={data => (
        <CookiesProvider>
          {typeof window !== 'undefined' && cookies.ipoRegulatory !== 'ye' && (<div className={`popup ${popupVisible ? 'popup--visible' : ''}`} id="popup">
            <div className="popup__body" style={{ maxWidth: '60em' }}>
              <div className="popup__content">
                <h1>{cookies.ipoRegulatory}</h1>
                <h1 className="margin-bottom-small">Disclaimer (viktig information)</h1>
                <div className="prose"
                  dangerouslySetInnerHTML={{
                    __html: data.datoCmsIpo.popup,
                  }}>
                </div>
                <div className="toolstrip flex--right toolstrip--no-margin">
                  <a href="/" className="btn btn--outline">Bekräftar inte</a>
                  <button className="btn" id="hide" onClick={acceptPopup}>Bekräftar</button>
                </div>
              </div>
            </div>
          </div>)}
        </CookiesProvider>)}
    />
  )
}
